import { useLayoutEffect } from 'react';
import {
  fetchRecommendedPortfolios,
  saveUserPreference,
} from '../../redux/modules/userPreference/userPreference.actions';
import { useTypedDispatch } from '../../redux/store';
import useGetAutoInvestedProductId from './useGetAutoInvestedProductId';
import { UserPreferenceTypeEnum } from '../../types/userPreference';

function useAutoSetProductId(firstSubscribedProductId: string) {
  const dispatch = useTypedDispatch();

  useLayoutEffect(() => {
    dispatch(fetchRecommendedPortfolios());
  }, []);

  const { autoInvestProductId, refetch } = useGetAutoInvestedProductId();

  useLayoutEffect(() => {
    (async () => {
      if (autoInvestProductId?.toString() === '-1' && firstSubscribedProductId) {
        await dispatch(
          saveUserPreference({
            type: UserPreferenceTypeEnum.AUTO_INVEST,
            value: `{\"productId\": \"${firstSubscribedProductId}\"}`,
          }),
        );

        await refetch();
      }
    })();
  }, [autoInvestProductId, firstSubscribedProductId]);
}

export default useAutoSetProductId;
